'use strict';

function Main(){

}

Main.prototype.init = function(){
    var self = this;
    $('#password-recover').on("submit", function () {
        var form = $(this);
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: form.serialize(),
            dataType: 'json',
            beforeSend: function () {
                form.find('.reset-btn').attr({'disabled':'disabled'}).addClass('loading-state');
                form.removeClass('form-error');
                $('.error-txt').html('');
            },
            success: function (resp) {
                form.find('.reset-btn').removeAttr('disabled').removeClass('loading-state');
                if(!$.isEmptyObject(resp.errors)){
                    validateForm(form, resp.errors);
                }
                else {
                    window.location.href = resp.data.url;
                }
            }
        });

        return false;
    });
};

var $main = new Main();

$main.init();

validateForm = function(form, errors){
    form.addClass('form-error');
    $.each(errors,function(name, error){
        $('#error-' + name, form).html(error);
    })

}